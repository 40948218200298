import { $ } from "../../utils/dom.js";
import { html, render } from "htm/preact/standalone.module.js";
import { PRNG } from "../../tiny-proc/index.js";
import "./style.css";

const PlayButton = () => html`
  <button class="play-button" aria-label="play video">
    <svg width="16" height="16" viewBox="0 0 8 8">
      <path fill="rgba(0,0,0,.5)" d="M1,0 L8,4 1,8z"/>
    </svg>
  </button>
`;

function GridItem({
  type = "image",
  variant = "normal",
  text,
  hash,
  onClick,
} = {}) {
  let thumbURL = "";
  if (hash) {
    thumbURL = `assets/${hash}-thumb.jpg`;
  } else {
    let id = (Math.random() * 32) | 0;
    let size = "128";
    if (variant === "large") {
      size = "256";
    }
    if (variant === "large-portrait") {
      size = "200/300";
    }
    if (variant === "large-landscape") {
      size = "300/200";
    }
    thumbURL = `https://picsum.photos/id/${id}/${size}`;
  }
  return html`
    <div class="grid__item grid--${variant}"
         onClick=${onClick}>
      <div class="grid__item__content" style="background-image: url(${thumbURL})">
        ${type === "video" && html`<${PlayButton}/>`}
        ${
          type === "text" &&
          html`
          <div class="grid__item__text-icon">
            💌
          </div>
        `
        }
      </div>
    </div>
  `;
}

const rng = new PRNG(1981040401);
const types = ["image", "video", "gif", "text"];
const variants = ["normal", "large", "large-landscape", "large-portrait"];
const pick = list => list[(Math.pow(Math.random(), 2) * list.length) | 0];

async function init() {
  let request = await fetch("assets/manifest.json?" + Date.now());
  let objects = await request.json();
  let items = [];
  for (let record of Object.values(objects)) {
    let variant = "normal";
    if (record.type === "image" && record.caption) {
      let aspectRatio = record.info.width / record.info.height;
      if (aspectRatio >= 4 / 3) {
        variant = "large-landscape";
      }
      if (aspectRatio <= 3 / 4) {
        variant = "large-portrait";
      }
    }
    if (record.type === "video") {
      let aspectRatio = record.info.width / record.info.height;
      if (aspectRatio >= 4 / 3) {
        variant = "large-landscape";
      }
      if (aspectRatio <= 3 / 4) {
        variant = "large-portrait";
      }
    }
    items.push({
      type: record.type,
      variant,
      hash: record.hash,
      text: record.caption,
      onClick: e => viewRecord(record.hash),
    });
  }

  let sortedItems = [];
  while (items.length) {
    sortedItems.push(items.splice(rng.int(items.length), 1)[0]);
  }
  items = sortedItems;

  items = items.map(i => GridItem(i));

  const videoPlayer = document.createElement("video");
  videoPlayer.setAttribute("controls", true);
  const largeImage = document.createElement("img");

  const viewRecord = hash => {
    let record = objects[hash];
    if (!record) return;
    console.log("showing record", record);
    const modalAsset = $(".modal__asset");
    const modal = $(".modal");
    modal.classList.toggle(
      "modal--large-caption",
      record.type === "json" || record.type === "text"
    );
    modal.classList.remove("modal--portrait");
    if (record.type === "image" && record.caption) {
      let aspectRatio = record.info.width / record.info.height;
      if (aspectRatio <= 3 / 4) {
        modal.classList.add("modal--portrait");
      }
    }

    modalAsset.innerHTML = "";
    if (record.type === "video") {
      modalAsset.append(videoPlayer);
      videoPlayer.width = record.info.width;
      videoPlayer.height = record.info.height;
      videoPlayer.src = "assets/" + record.file;
      videoPlayer.play();
    }
    if (record.type === "image") {
      modalAsset.append(largeImage);
      largeImage.width = record.info.width;
      largeImage.height = record.info.height;
      largeImage.src = "assets/" + record.file;
    }
    render(
      html`
      ${
        record.caption &&
        html`<span class="caption__text">${record.caption}</span>`
      }
      ${
        record.from &&
        html`<span class="caption__from">from <b>${record.from}</b></span>`
      }
    `,
      $(".modal__caption")
    );
    modal.classList.add("show");
  };

  const dismissModal = () => {
    const modal = $(".modal");
    modal.classList.remove("show");
    videoPlayer.pause();
  };

  document.body.addEventListener("keydown", e => {
    if (e.key === "Escape") dismissModal();
  });

  render(
    html`
      <h1>Happy 40<sup>th</sup> Anniversary, Jack and Marianne!</h1>
      <div class="grid">
        ${items}
      </div>
      <div class="modal"
           onClick=${e => dismissModal()}>
        <div class="modal__content" onClick=${e => e.stopPropagation()}>
          <div class="modal__asset"></div>
          <div class="modal__caption">
          </div>
        </div>
        <button class="modal__close" onClick=${e => dismissModal()}>
          <svg width="16" height="16" viewBox="0 0 8 8">
            <path stroke="#fff" stroke-linecap="round" d="M1,1 L7,7 M1,7 L7,1"/>
          </svg>
        </button>
      </div>
    `,
    $(".container")
  );
}

window.addEventListener("load", e => init().catch(e => console.log(e)));
