import htm from "../lib/build/htm.js";

export const $ = selector => document.querySelector(selector);

export const makeEl = (tag, attrs, ...children) => {
  attrs = attrs || {};
  let el = document.createElement(tag);
  for (let [attr, value] of Object.entries(attrs)) {
    el.setAttribute(attr, value);
  }
  children.flat().forEach(c => {
    if (c instanceof Node && c.parentNode) {
      c = c.cloneNode(true);
    }
    if (typeof c === "function") {
      c = c(el);
    }
    if (typeof c !== "undefined") {
      el.append(c);
    }
  });
  el._el = children
    .filter(c => c instanceof HTMLElement && c.getAttribute("key"))
    .map(c => [c.getAttribute("key"), c])
    .reduce((memo, [key, el]) => Object.assign(memo, { [key]: el }), {});
  return el;
};

export const markup = string => el =>
  el.insertAdjacentHTML("beforeend", string);

export const html = htm.bind(makeEl);

export const render = (el, tree) => {
  if (tree instanceof Array) {
    el.append(...tree);
  } else {
    el.append(tree);
  }
};
