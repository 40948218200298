export class Random {
  next() {
    return this.float() * Number.MAX_SAFE_INTEGER;
  }
  int(max, min = 0) {
    if (max) {
      return (this.float() * (max - min) + min) | 0;
    } else {
      return this.next();
    }
  }
  float() {
    return Math.random();
  }
  roll(n = 1, sides = 6) {
    let total = 0;
    for (let i = 0; i < n; i++) {
      total += this.int(sides) + 1;
    }
    return total;
  }
  // lots is an Array of [option, weight]
  // optional sum of weights for perf
  weighted(lots, sum) {
    if (!sum) {
      sum = 0;
      for (let option of lots) {
        sum += option.weight || 1;
      }
    }
    let roll = this.int(sum);
    let total = 0;
    for (let option of lots) {
      total += option.weight || 1;
      if (roll < total) return option;
    }
  }
}

// garbage but servicable prng
export class PRNG extends Random {
  constructor(seed = 1) {
    super();
    this._seed = seed % 2147483647;
    if (this._seed <= 0) this._seed += 2147483646;
  }
  next() {
    return (this._seed = (this._seed * 16807) % 2147483647);
  }
  float() {
    return (this.next() - 1) / 2147483646;
  }
}

// L-System generation
export class LSystem {
  constructor({ rules, rng, seed } = {}) {
    if (rng) {
      this.rng = rng;
    } else if (seed) {
      this.rng = new PRNG(seed);
    } else {
      this.rng = new Random();
    }
    this.rules = rules;
  }

  generation(input) {
    let output = "";
    for (let symbol of input) {
      let matchingRules = this.rules.filter((r) => r.symbol === symbol);
      if (matchingRules.length) {
        // pick one matching rule
        output += this.rng.weighted(matchingRules).output;
      } else {
        // pass through
        output += symbol;
      }
    }
    return output;
  }

  iterate(input) {
    let output;
    let pos = this.rng.int(input.length);
    let symbol = input[pos];
    let matchingRules = this.rules.filter((r) => r.symbol === symbol);
    if (matchingRules.length) {
      // pick one matching rule
      output = this.rng.weighted(matchingRules).output;
    } else {
      // pass through
      output = symbol;
    }
    return input.slice(0, pos) + output + input.slice(pos + 1);
  }
}

// adapted from https://asserttrue.blogspot.com/2011/12/perlin-noise-in-javascript_31.html
export class Perlin {
  constructor({ rng, seed } = {}) {
    if (!rng) {
      this.rng = new Random();
    }
    if (seed) {
      this.rng = new PRNG(seed);
    }
    this.p = new Array(512);
    let permutation = [...new Array(256)].map((_, i) => i);
    for (let i = 0; i < 256; i++) {
      this.p[i] = this.p[i + 256] = permutation.splice(
        this.rng.int(permutation.length),
        1
      )[0];
    }
  }
  octaves(x, y, z, o) {
    let v = 0;
    for (let i = 0; i < o; i++) {
      let s = 1 << i;
      v += this.at(x * s, y * s, z * s) / s;
    }
    return v;
  }
  at(x, y, z) {
    let { scale, fade, grad } = Perlin;
    let p = this.p;

    // FIND UNIT CUBE THAT CONTAINS POINT.
    let X = Math.floor(x) & 255,
      Y = Math.floor(y) & 255,
      Z = Math.floor(z) & 255;

    // FIND RELATIVE X,Y,Z OF POINT IN CUBE.
    x -= Math.floor(x);
    y -= Math.floor(y);
    z -= Math.floor(z);

    // COMPUTE FADE CURVES FOR EACH OF X,Y,Z.
    let u = fade(x),
      v = fade(y),
      w = fade(z);

    let A = p[X] + Y,
      AA = p[A] + Z,
      AB = p[A + 1] + Z,
      B = p[X + 1] + Y,
      BA = p[B] + Z,
      BB = p[B + 1] + Z;

    // AND ADD BLENDED RESULTS FROM 8 CORNERS OF CUBE
    return lerp(
      w,
      lerp(
        v,
        lerp(u, grad(p[AA], x, y, z), grad(p[BA], x - 1, y, z)),
        lerp(u, grad(p[AB], x, y - 1, z), grad(p[BB], x - 1, y - 1, z))
      ),
      lerp(
        v,
        lerp(u, grad(p[AA + 1], x, y, z - 1), grad(p[BA + 1], x - 1, y, z - 1)),
        lerp(
          u,
          grad(p[AB + 1], x, y - 1, z - 1),
          grad(p[BB + 1], x - 1, y - 1, z - 1)
        )
      )
    );
  }
  static fade(t) {
    return t * t * t * (t * (t * 6 - 15) + 10);
  }
  static grad(hash, x, y, z) {
    // CONVERT LO 4 BITS OF HASH CODE INTO 12 GRADIENT DIRECTIONS.
    let h = hash & 15;
    let u = h < 8 ? x : y,
      v = h < 4 ? y : h == 12 || h == 14 ? x : z;
    return ((h & 1) == 0 ? u : -u) + ((h & 2) == 0 ? v : -v);
  }
  static scale(n) {
    return (1 + n) / 2;
  }
}

export const lerp = (t, a, b) => a + t * (b - a);

export const NORTH = "NORTH";
export const SOUTH = "SOUTH";
export const EAST = "EAST";
export const WEST = "WEST";

export const REVERSE = {
  [NORTH]: SOUTH,
  [SOUTH]: NORTH,
  [WEST]: EAST,
  [EAST]: WEST,
};

export class Node {
  constructor(data) {
    Object.assign(this, data);
    this.neighbor = {};
  }
  connect(dir, c, mutual = true) {
    this.neighbor[dir] = c;
    if (mutual) {
      c.neighbor[REVERSE[dir]] = this;
    }
  }
  neighbors() {
    return Object.values(this.neighbor);
  }
  equals(c) {
    return this.value === c.value;
  }
  toString() {
    if ("glyph" in this) {
      return this.glyph;
    }
    if ("value" in this) {
      return this.value.toString();
    }
    return "";
  }
}

export class Grid {
  constructor(options = { setCoords: true }) {
    this.cells = new Map();
    Object.assign(this, options);
  }

  add(value) {
    if (value.coords && value.coords.x && value.coords.y) {
      this.set(value.coords.x, value.coords.y, value);
    }
  }

  get(x, y) {
    let key = Grid.key(x, y);
    if (this.cells.has(key)) {
      return this.cells.get(key);
    }
    return null;
  }

  set(x, y, value) {
    let { cells } = this;
    let key = Grid.key(x, y);
    cells.set(key, value);
    if (value instanceof Node) {
      if (this.setCoords) {
        value.coords = { x, y };
      }
      for (let [dir, cell] of this.findNeighbors(x, y)) {
        if (cell instanceof Node) {
          value.connect(dir, cell);
        }
      }
    }
  }

  delete(x, y) {
    this.cells.delete(Grid.key(x, y));
  }

  findNeighbors(x, y) {
    return [
      [NORTH, this.get(x, y - 1)],
      [SOUTH, this.get(x, y + 1)],
      [EAST, this.get(x + 1, y)],
      [WEST, this.get(x - 1, y)],
    ];
  }

  bbox() {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (let key of this.cells.keys()) {
      let [x, y] = key.split(",");
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    }
    return [minX, minY, maxX, maxY];
  }

  toString({ pad = 1 } = {}) {
    let [minX, minY, maxX, maxY] = this.bbox();
    if (isFinite(minX)) {
      let out = "";
      for (let y = minY; y <= maxY; y++) {
        for (let x = minX; x <= maxX; x++) {
          let v = this.get(x, y);
          let s = v ? v.toString() : "";
          out += s.padEnd(pad, " ");
        }
        if (y < maxY) {
          out += "\n";
        }
      }
      return out;
    }
    return "";
  }

  static key(x, y) {
    return x + "," + y;
  }
}

export class BoundedGrid {
  constructor(width, height = width) {
    this.width = width;
    this.height = height || width;
    this.length = width * height;
    this.cells = new Array(this.length);
    for (let i = 0; i < this.length; i++) {
      let cell = new Node({
        key: i,
        x: i % width,
        y: (i / width) | 0,
      });
      this.cells[i] = cell;
      if (cell.x > 0) cell.connect(WEST, this.cells[i - 1]);
      if (cell.y > 0) cell.connect(NORTH, this.cells[i - width]);
    }
    this.root = this.cells[0];
  }
  get(x, y) {
    if (this.exists(x, y)) {
      return this.cells[y * this.width + x];
    }
    return null;
  }
  exists(x, y) {
    return !(x < 0 || x >= this.width || y < 0 || y >= this.height);
  }
  toString({ lookup, pad } = {}) {
    let s = "";
    for (let i = 0; i < this.length; i++) {
      let v = this.cells[i].toString();
      if (pad) {
        v = v.padStart(pad, " ");
      }
      s += v;
      if (i < this.length - 1 && (i + 1) % this.width === 0) {
        s += "\n";
      }
    }
    return s;
  }
  forEach(fn) {
    this.cells.forEach((cell, i) => {
      fn(cell);
    });
  }
  filter(fn) {
    let out = [];
    this.cells.forEach((cell, i) => {
      let x = i % this.width;
      let y = (i / this.width) | 0;
      if (fn(cell, x, y)) {
        out.push(cell);
      }
    });
    return out;
  }
  static fromArray(source, width) {
    let height = (source.length / width) | 0;
    let g = new Grid(width, height);
    for (let i = 0; i < source.length; i++) {
      Object.assign(g.cells[i], source[i]);
    }
    return g;
  }
}

export function flood(cell, fn) {
  const seen = new Set();
  const toVisit = [[cell, 0]];
  const filter = fn || ((a, b) => a.value === b.value);
  const out = [];
  while (toVisit.length) {
    let [c, distance] = toVisit.shift();
    out.push([c, distance]);
    seen.add(c);
    c.neighbors().forEach((n) => {
      if (!seen.has(n) && filter(c, n)) {
        seen.add(n);
        toVisit.push([n, distance + 1]);
      }
    });
  }
  return out;
}

export function walk(cell, fn) {
  const seen = new Set();
  const toVisit = [cell];
  while (toVisit.length) {
    let c = toVisit.pop();
    seen.add(c);
    fn(c);
    c.neighbors().forEach((n) => {
      if (!seen.has(n)) toVisit.push(n);
    });
  }
}
